import '../../scss/layout/teacherCalendar.scss';

(function() {
    $.fn.bootstrapDatepicker = function(options) {
        options = $.extend({
            format: 'DD.MM.YYYY', // momentjs format
            displayFormat: 'LL',
            placement: 'bottom'
        } ,options || {});

        this.filter("input").each(function() {
            var el = $(this);
            el.attr("type", "hidden");
            var input = $('<a class="btn btn-light btn-block" href="javascript:void(0)"><i class="fa fa-calendar align-middle" aria-hidden="true"></i><span class="ml-3"></span></a>');
            el.after(input);
            var span = input.find("span");
            var setDate = function (m) {
                el.val(m.format(options.format));
                span.text(m.format(options.displayFormat));
            }
            var getDate = function() {
                var m = moment(el.val(), options.format);
                if(!m.isValid()) {
                    m = moment();
                }
                return m;
            }
            if(el.val()) {
                setDate(getDate());
            }
            input.popover({
                placement: options.placement,
                html: true,
                content : function() {
                    var root = $('<div>');
                    var buildSwitch = function(txt) {
                        return $('<a href="javascript:void(0)" class="btn btn-light btn-sm btn-block">' + txt + '</a>');
                    }
                    var setTop = function(m, thead, topLabel, unit, unitType, cb, upperCb, cols) {
                        var prec = $('<a href="javascript:void(0)" class="btn btn-light btn-sm btn-block"><i class="fa fa-arrow-circle-left"></i></a>');
                        var next = $('<a href="javascript:void(0)" class="btn btn-light btn-sm btn-block"><i class="fa fa-arrow-circle-right"></i></a>');
                        var sw = buildSwitch(topLabel);
                        var row = $('<tr>');
                        thead.append(row);
                        row.append($('<th>').append(prec).click(function() {
                            m.add(-1 * unit, unitType);
                            setDate(m);
                            cb();
                        }));
                        row.append($('<th colspan="' + cols + '">').append(sw).click(function() {
                            upperCb();
                        }));
                        row.append($('<th>').append(next).click(function() {
                            m.add(unit, unitType);
                            setDate(m);
                            cb();
                        }));
                    }
                    var set12 = function(cb, step, tmp, m, div, unit, format) {
                        unit = unit || 'years';
                        format = format || 'YYYY';
                        var row;
                        var tbody = $('<tbody>');
                        div.append(tbody);
                        for(var i=0;i<12;i++) {
                            if(i % 4 == 0) {
                                row = $('<tr>');
                                tbody.append(row);
                            }
                            var month = $('<td class="pick" data-date="' + tmp.format('YYYY-MM-DD') + '"><a href="javascript:void(0)" class="btn btn-sm btn-block">' + tmp.format(format) + '</a></td>');
                            if(tmp.valueOf() == m.valueOf()) {
                                month.find("a").addClass("btn-primary");
                            } else {
                                month.find("a").addClass("btn-light");
                            }
                            row.append(month);
                            tmp.add(step, unit);
                        }
                        tbody.find(".pick").click(function() {
                            setDate(moment($(this).attr('data-date'), 'YYYY-MM-DD'));
                            cb();
                        });
                    }
                    var set100Year = function() {
                        var m = getDate();
                        var div = $('<table>');
                        var thead = $('<thead>');
                        div.append(thead);
                        var currentYear = m.year();
                        var start = currentYear - (currentYear % 100) - 10;
                        setTop(m, thead, (start + 10) + " - " + (start + 100), 100, 'years', set100Year, function(){}, 2);
                        set12(set10Year,10, m.clone().year(start), m, div);
                        root.empty().append(div);
                        input.popover('update');
                    }
                    var set10Year = function() {
                        var m = getDate();
                        var div = $('<table>');
                        var thead = $('<thead>');
                        div.append(thead);
                        var currentYear = m.year();
                        var start = currentYear - (currentYear % 10) - 1;
                        setTop(m, thead, (start + 1) + " - " + (start + 10), 10, 'years', set10Year, set100Year, 2);
                        set12(setYear,1, m.clone().year(start), m, div);
                        root.empty().append(div);
                        input.popover('update');
                    }
                    var setYear = function() {
                        var m = getDate();
                        var div = $('<table>');
                        var thead = $('<thead>');
                        div.append(thead);
                        setTop(m, thead, m.format("YYYY"), 1, 'years', setYear, set10Year, 2);
                        set12(setMonth,1, m.clone().month(0), m, div, 'months', 'MMM');
                        root.empty().append(div);
                        input.popover('update');
                    }
                    var setMonth = function() {
                        var m = getDate();
                        var div = $('<table>');
                        var thead = $('<thead>');
                        div.append(thead);
                        setTop(m, thead, m.format("MMMM YYYY"), 1, 'months', setMonth, setYear, 5);
                        var row = $('<tr>');
                        thead.append(row);
                        var tmp = moment();
                        for(var i=0;i<7;i++) {
                            tmp.day(i)
                            row.append($('<th class="text-center">' + tmp.format('dd') + '</th>'));
                        }
                        var tbody = $('<tbody>');
                        div.append(tbody);
                        var hasMore = true;
                        tmp = m.clone();
                        var currentMonth = tmp.month();
                        tmp.date(1);
                        tmp.day(0);
                        while(hasMore) {
                            row = $('<tr>');
                            tbody.append(row);
                            for(var i=0;i<7;i++) {
                                var day = $('<td class="pick" data-date="' + tmp.format('YYYY-MM-DD') + '"><a href="javascript:void(0)" class="btn btn-sm btn-block">' + tmp.format('D') + '</a></td>');
                                if(tmp.valueOf() == m.valueOf()) {
                                    day.find("a").addClass("btn-primary");
                                } else if(tmp.month() == currentMonth) {
                                    day.find("a").addClass("btn-light");
                                } else {
                                    day.find("a").addClass("btn-light text-muted");
                                }
                                row.append(day);
                                tmp.add(1, 'days');
                            }
                            hasMore = tmp.month() == currentMonth;
                        }
                        tbody.find(".pick").click(function() {
                            setDate(moment($(this).attr('data-date'), 'YYYY-MM-DD'));
                            input.popover('hide');
                        });
                        root.empty().append(div);
                        input.popover('update');
                    }
                    setMonth();
                    return root;
                },
                template: '<div class="popover" role="tooltip"><div class="arrow"></div><div class="popover-body"></div></div>'
            });
        });

        return this;
    };
})();