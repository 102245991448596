/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../scss/app.scss';
import '../css/_app.css';

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

const moment = require('moment');
moment.locale('pl');
window.moment = moment;

const bootbox = require('bootbox')
window.bootbox = bootbox;

$(document).ready(function (){
    $('.dataTable').DataTable({
        language: {
            url: 'https://cdn.datatables.net/plug-ins/1.10.24/i18n/Polish.json'
        },
        autoWidth: false,
        responsive: true,
        columnDefs: [
            { responsivePriority: 1, targets: 1 },
            { responsivePriority: 2, targets: -1 }
        ]
    });
});

require('./teacher/datepicker');